import { template as template_63103eeab8b1494898c87c885d286a90 } from "@ember/template-compiler";
const FKControlMenuContainer = template_63103eeab8b1494898c87c885d286a90(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
