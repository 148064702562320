import { template as template_4ae8043fc53a497995604c45f5e9fd98 } from "@ember/template-compiler";
const WelcomeHeader = template_4ae8043fc53a497995604c45f5e9fd98(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
