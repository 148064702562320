import { template as template_6f2a2f3b944b4e7486de206e213b3b7e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_6f2a2f3b944b4e7486de206e213b3b7e(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
