import { template as template_14fc33678a3e4c2e9c6889c4670df5d6 } from "@ember/template-compiler";
const FKText = template_14fc33678a3e4c2e9c6889c4670df5d6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
